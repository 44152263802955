import * as React from 'react';
import { Link } from 'gatsby';
import Head from '../components/Head';
import AnimeLink from '../components/AnimeLink';

const NotFoundPage = () => {
  return (
    <>
      <Head
        title="404 NotFound."
        description="お探しのページが見つかりませんでした"
      />
      <main className="body">
        <article>
          <header>
            <h1>404 NotFound.</h1>
          </header>
          <section className="article-body">
            <h2>ここには何もありません！</h2>
            <AnimeLink to="/">トップページに戻る</AnimeLink>
          </section>
        </article>
      </main>
    </>
  );
};

export default NotFoundPage;
